<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body class="mb-50">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button size="sm" variant="primary" @click="isActiveModal = true">
                            <span class="text-nowrap">
                                Agregar
                            </span>
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <!-- <b-input-group>
                                <b-form-input size="sm" v-model="searchQuery" class="d-inline-block _mr-1" placeholder="Buscar por nombre"/>
                                <b-input-group-prepend>
                                <b-button size="sm" variant="primary">
                                    <feather-icon icon="SearchIcon" /> Buscar
                                </b-button>
                                </b-input-group-prepend>                                
                            </b-input-group> -->
                            <b-input-group>
                                <b-form-input size="sm" v-model="searchQuery" placeholder="Buscar por nombre" />
                                <b-input-group-append>
                                <b-button size="sm" variant="primary">
                                    <feather-icon icon="SearchIcon" /> Buscar
                                </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <div class="table-responsive">
                <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                            :class="`${listUsers.length > 10 ? 'b-table-sticky-header' : ''}`">
                    <table ref="articlesTable" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Nombre</th>
                                <th>Vendedor</th>
                                <th>Perfil</th>
                                <th>Conexiones</th>
                                <th>Almacén</th>
                                <!-- <th>Correo</th> -->
                                <!-- <th>Teléfono</th> -->
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in listUsers" :key="index">
                                <td>{{ user.userName }}</td>
                                <td>{{ user.fullName }}</td>
                                <td>{{ user.vendedoR_ID ? `${user.vendedoR_ID} - ${user.vendedoR_NOMBRE}` : '' }}</td>
                                <td>{{ user.roleName }}</td>
                                <td v-html="user.conexiones.map(c => `${c.conexiones.nombre} (${c.sucursal_Nombre})`).join('<br> ')"></td>
                                <td class="text-wrap">
                                    <span class="text-wrap">
                                        {{ user.almacenes.map(a => a.almacen_Nombre).join(', ') }}
                                        <feather-icon 
                                            v-if="user.almacenes.length > 0" 
                                            :id="`popover-button-help-${index}`" 
                                            size="18" 
                                            icon="AlertCircleIcon" 
                                            @click="hidePopover(index)" 
                                        />

                                        <b-popover v-if="user.almacenes.length > 0" 
                                            :target="`popover-button-help-${index}`" placement="top"  triggers="hover"  variant="primary">
                                            <template #title>
                                                Información de almacén
                                            </template>

                                            <b-list-group>
                                                <b-list-group-item v-for="(item, idx) in user.almacenes" :key="idx+index">
                                                    <b-card-text>
                                                        {{ item.conexiones.nombre }}
                                                    </b-card-text>
                                                    <p>
                                                        <small class="text-secondary">
                                                            Almacén: {{ item.almacen_Nombre }}
                                                        </small>
                                                    </p>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-popover>
                                    </span>
                                </td>
                                <!-- <td>{{ user.email }}</td> -->
                                <!-- <td>{{ user.phoneNumber }}</td> -->
                                <td>
                                    <div class="demo-inline-spacing">
                                        <b-button v-if="user.estatus" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" size="sm" @click="editUser(user)" v-b-tooltip.hover.right="`Editar`">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>

                                        <b-button v-if="user.id !== '3ced1e3f-b935-4435-9746-9f37032b6751' && user.estatus" v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                                            variant="danger" class="btn-icon" size="sm" 
                                            @click="deleteUser(user)" v-b-tooltip.hover.right="`Desactivar`">
                                            <feather-icon icon="TrashIcon" />
                                        </b-button>

                                        <b-button v-if="!user.estatus" v-ripple.400="'rgba(255, 255, 255, 0.15)'" 
                                            variant="warning" class="btn-icon" size="sm" 
                                            @click="deleteUser(user)" v-b-tooltip.hover.right="`Reactivar`">
                                            <feather-icon icon="CheckIcon" />
                                        </b-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-card>

        <!-- Modal Roles -->
        <b-modal ref="infoModalRef" id="modalCreateUpdateProducts" :title="titleModal" no-close-on-backdrop hide-footer
            @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal" size="lg">
            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" ref="formRef" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row>

                        <b-col cols="6">
                            <b-form-group label="Nombre Completo" label-for="fullName">
                                <validation-provider #default="{ errors }" name="nombre completo" rules="required">
                                <b-form-input size="sm" id="fullName" v-model="user.fullName" :state="errors.length > 0 ? false : null"
                                    name="fullName" placeholder="Nombre Completo" />
                                    <small v-if="errors[0]" class="text-danger">El nombre completo es requerido.</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Email -->
                        <b-col cols="12" md="6">                            
                            <b-form-group label="Correo" label-for="email">
                                <b-form-input size="sm" :disabled="isDetail" id="email" v-model="user.email" trim
                                    placeholder="Insertar Correo" />
                            </b-form-group>
                        </b-col>

                        <!-- Phone -->
                        <b-col cols="12" md="6">                            
                            <b-form-group label="Teléfono" label-for="phone">
                                <b-form-input size="sm" :disabled="isDetail" id="phone" v-model="user.phoneNumber" trim
                                    placeholder="Insertar Teléfono" />
                            </b-form-group>
                        </b-col>

                        <!-- UserName -->
                        <b-col cols="12" md="6">                            
                            <b-form-group label="Usuario" label-for="userName">
                                <validation-provider #default="{ errors }" name="usuario" rules="required">
                                    <b-form-input size="sm" :disabled="isDetail" id="userName" v-model="user.userName" trim
                                        placeholder="Insertar Usuario" :state="errors.length > 0 ? false : null"/>
                                        <small v-if="errors[0]" class="text-danger">El usuario es requerido.</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Password -->
                        <b-col cols="12" md="6">                            
                            <b-form-group label="Contraseña" label-for="password" :description="isEdit ? `La contraseña solo se guarda si se escribe algo` : ``">
                                <validation-provider #default="{ errors }" name="contraseña" :rules="isEdit ? '' : 'required'">
                                    <b-input-group class="input-group-merge">
                                        <b-form-input size="sm" id="password" v-model="user.password" :type="passwordFieldType"
                                            placeholder="Insertar Contraseña" :state="errors.length > 0 ? false : null"/>
                                        <b-input-group-append is-text :class="errors.length > 0 ? 'is-invalid':''">
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePassword"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>

                                    <small v-if="errors[0]" class="text-danger">La contraseña es requerido.</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- Role -->
                        <b-col cols="6">
                            <validation-provider #default="{ errors }" name="rol de usuario" rules="required">
                                <b-form-group label="Perfil de Usuario" label-for="user-role" :class="errors.length > 0 ? 'is-invalid':''">                                
                                    <v-select v-model="user.roleName" :options="roles" class="select-size-sm"
                                        :reduce="(val) => val.name"
                                        :clearable="false"
                                        :searchable="true"
                                        input-id="user-role"
                                        label="name"
                                        placeholder="Selecciona una opción"
                                        :state="errors.length > 0 ? false : null"
                                        @input="selectedRole"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        El perfil de usuario es requerido
                                    </b-form-invalid-feedback>                       
                                </b-form-group>                                
                            </validation-provider>
                        </b-col>

                        <b-col v-if="isSeller" cols="6">
                            <validation-provider #default="{ errors }" name="usuario vendedor" :rules="isSeller ? 'required' : ''">
                                <b-form-group label="Vendedor" label-for="user-seller"
                                :class="errors.length > 0 ? 'is-invalid':''">
                                    <v-select v-model="user.vendedor_Id" :options="sellers"
                                        :reduce="(option) => option.vendedor_Id"
                                        :clearable="false"
                                        :searchable="true"
                                        input-id="user-seller"
                                        label="nombre"
                                        placeholder="Selecciona una opción"
                                        class="select-size-sm"
                                        :state="errors.length > 0 ? false : null"
                                    />

                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Debe agregar un venedor al nuevo usuario
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>


                    <!-- Form Actions -->
                    <div class="demo-inline-spacing float-right">
                        <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button"
                            variant="outline-secondary" @click="closeModal">
                            Cancelar
                        </b-button>
                        <b-button size="sm" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            type="submit">
                            Guardar
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

    </b-overlay>
</template>

<script>
import _ from "lodash";
import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BFormCheckbox,
    VBPopover, 
    BPopover,
    BListGroup, 
    BListGroupItem,
    BOverlay,
    
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "animate.css";
import draggable from 'vuedraggable'

import UsersProvider from "@/providers/Users";
const UsersResource = new UsersProvider();

import RolesProvider from "@/providers/Roles";
import store from '@/store';
const RolesResource = new RolesProvider();

import SellersProvider from '@/providers/Sellers'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
const SellersResource = new SellersProvider()

if (process.env.MIX_FALLBACK_LOCALE === "es") {
    localize('es');
}

export default {
    name: 'UsersList',
    components: {
        BOverlay,
        BCard,
        BCardText,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BSpinner,
        BTooltip, 
        VBTooltip,
        VBToggle,
        vSelect,
        BFormCheckbox,
        BSidebar,
        draggable,
        VBPopover, 
        BPopover,
        BListGroup, 
        BListGroupItem,

        //
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
        'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
        'b-toggle': VBToggle,
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            password,
            hideSidebar: false,
            users: [],
            user: {},
            perPageOptions: [10, 25, 50, 100],
            perPage: 10,
            currentPage: 1,
            totalUsers: 0,
            columns: [               
                {
                    key: "userName",
                    label: 'Usuario',
                }, 
                {
                    key: "fullName",
                    label: 'Nombre',
                },
                {
                    key: 'vendedoR_ID',
                    label: 'Vendedor',
                },
                {
                    key: 'roleName',
                    label: 'Perfil',
                },
                {
                    key: 'conexiones',
                    label: 'Conexiones',
                },
                {
                    key: 'almacenes',
                    label: 'Almacén',
                },
                {
                    key: "email",
                    label: 'Correo',
                },
                {
                    key: "phoneNumber",
                    label: 'Teléfono',
                },
                { key: "actions", label: 'Acciones' },
            ],
            sortBy: "id",
            sortDesc: true,
            searchQuery: null,
            from: 0,
            to: 0,
            loading: false,
            isEdit: false,
            isActiveModal: false,
            isDetail: false,
            titleModal: "Alta de Usuario",
            roles: [],
            passwordFieldType: "password",
            roleSeller: '',
            isSeller: false,
            sellers: [],
        };
    },
    computed: {
        passwordToggleIcon() { 
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
        },
        listUsers () {            
            let result = this.searchQuery ? this.users.filter(o =>
                    o.fullName?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    o.vendedoR_NOMBRE?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    o.roleName?.toLowerCase().includes(this.searchQuery.toLowerCase())
                ) : this.users;
            return result
        }
    },
    watch: {
       
    },
    async mounted() {
        await this.getRoles()
        await this.getSellers()
        await this.getUsers()
    },
    methods: {
        hidePopover(index) {
            this.$root.$emit('bv::hide::popover', `popover-button-help-${index}`)
        },
        selectedRole(evt) {
            this.isSeller = false
            if (evt) {
                const foundRole = this.roles.find(role => role.name === evt);
                if (foundRole && foundRole.id === store.getters['auth/getIsRoleSeller']) {
                    this.isSeller = true
                }
            }

            if (!this.isSeller) {
                this.user.vendedor_Id = null
            }
        },
        async getSellers() {
            try {
                this.sellers = []
                this.loading = true
                const { data } = await SellersResource.getAll()
                this.loading = false
                if (data.isSuccesful) {
                    this.sellers = data.data
                }
            }catch(e) {
                this.loading = false
                console.log(JSON.stringify(e))    
            }
        },
        onDragEnd(newFields) {
            this.columns = newFields;
        },
        getRules() {
             // Si es un nuevo usuario, la validación es requerida
            if (!this.isEdit) {
                return 'required';
            } else {
                // Si es una actualización, la validación no es requerida
                return '';
            }
        },
        togglePassword() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
        },
        async getRoles() {
            this.loading = true
            const { data } = await RolesResource.index()
            this.loading = false
            this.roles = data
        },
        closeModal() {
            this.isActiveModal = false
            this.isEdit = false
            this.user = {}
            this.titleModal = 'Alta de Usuario'
        },
        validatePassword() {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{6,}$/;
            if (!passwordRegex.test(this.user.password)) {
                this.danger('La contraseña debe tener al menos 6 caracteres y contener al menos una letra mayúscula, una letra minúscula, un dígito y un carácter especial.');
                return false
            }
            return true
        },
        async onSubmit () {

            let sellerName = null
            const finded = this.sellers.find(x => x.vendedor_Id === this.user.vendedor_Id)
            if (finded) {
                sellerName = finded.nombre
            }

            const payload = {                
                id: this.user.id,
                userName: this.user.userName,
                fullName: this.user.fullName,
                email: this.user.email,
                phoneNumber: this.user.phoneNumber,
                password: this.user.password || '',
                roleName: this.user.roleName,
                editPassword: this.user.password ? true : false,
                idEmpresa: store.getters['auth/getConexionId'],
                vendedoR_ID: this.isSeller && this.user.vendedor_Id != null ? this.user.vendedor_Id : null,
                vendedoR_NOMBRE: this.isSeller && this.user.vendedor_Id ? sellerName : null,
                DeviceId: ' -- '

            }
            
            if (this.isSeller) {
                if (!payload.vendedoR_ID) {
                    this.danger(`Es necesario agregar un vendedor al nuevo usuario`)
                    return false
                }
            }

            if (this.user.password) {
                if (!this.validatePassword()) {
                    return false
                }
            }

            if (!payload.phoneNumber || payload.phoneNumber == "") {
                delete payload.phoneNumber
            }

            if (!payload.password || payload.password == "") {
                delete payload.password
            }

            try {

                this.loading = true                
                const { data } = this.isEdit ? await UsersResource.update(this.user.id, payload) : await UsersResource.store(payload);
                this.loading = false
                if (data.isSuccesful) {
                    this.success(data.message);
                    await this.getUsers()
                    this.isActiveModal = false
                    this.user = {}
                    this.isEdit = false

                } else {
					this.danger(data.message);
                }
            } catch (e) {
                console.log(e)
                this.loading = false
                this.handleResponseErrors(e)
            }
        },       
        editUser(item) {
            this.isEdit = true
            this.user = {...item, vendedor_Id: item.vendedoR_ID }
            this.isActiveModal = true
            this.titleModal = 'Editar Usuario'  
                   
        },
        async getUsers() {
            this.loading = true;
            const { data } = await UsersResource.index();
            this.loading = false;
            this.users = data.map(u => ({...u.user, 
                conexiones: u.especiales,
                almacenes: u.almacenes, roleName: u.roles[0]}))
        },
        deleteUser(item) {

            const title = item.estatus ? 'desactivar' : 'reactivar'
            const text = item.estatus ? 'ya no podra iniciar session en el sistema' : ' podra iniciar session en el sistema'

            this.$swal({
                title: `¿Desea ${title} el registro?`,
                text: `Al ${title} el usuario, ${text}`,
                icon: "question",
                showCancelButton: true,
                confirmButtonText: `Continuar`,
                cancelButtonText: `Cancelar`,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await UsersResource.destroy(item.id)
                        this.loading = false

                        if (data.isSuccesful) {
                            this.success(data.message)
                            //this.users = this.users.filter(user => user.id !== item.id)
                            await this.getUsers()
                        } else {
                            this.$swal.showValidationMessage(
                                `Request failed: ${data.message}`
                            );
                        }
                    }catch(e) {
                        this.$swal.showValidationMessage(
                            `Request failed: ${e}`
                        );
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
    },
    watch: {
        user(value) {
            this.isSeller = false
            const { roleName } = value;
            const foundRole = this.roles.find(role => role.name === roleName);
            this.$nextTick(async () => {
                if (foundRole && foundRole.id === store.getters['auth/getIsRoleSeller']) {
                    this.isSeller = true;
                } else {
                    this.user.vendedor_Id = ''
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    /* max-height: 480px; */
    max-height: calc(100vh - 280px)!important;
}
</style>
